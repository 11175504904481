/* =============================================================================
   Primary Styles
   Author: rsacek
   ========================================================================== */





/*
  Imports
  Modify Bootstrap defaults...
*/
$enable-validation-icons: false;
$input-placeholder-color: #528afe;
$modal-backdrop-bg: rgba(250, 246, 245, 1.0);



@import "~bootstrap/scss/bootstrap";
//@import "fonts/FontAwesome/solid";



/*

** z-index usage **

1     Key Cards Backdrop over card
2     Key Cards Overlay image.

1     SpeakerProfileModal - close button (top/right)

499 Fixed Right Floating button


500  CategoryFilterSelection - Full screen mask - NEEDS TO BE HIGH (1000)
501  CategoryFilterSelection - Menu, sits above fullscreen mask.   (1001)

500  FilterSelectionGeneric - Full screen mask - NEEDS TO BE HIGH  (1000)
501  FilterSelectionGeneric - Menu, sits above fullscreen mask.    (1001)


1040  Backdrop     - Used by SubmitOverlay
1040  Large Spinner


600  ISI Product floating bottom fixed popup
601  Cookie Consent - Footer

602 Return To Top floating button


500    Header - fullNavBarMask      - Full Nav Mask
501    Header - dropdown            - Full Nav Top Menu Items (In top nav bar)
502    Header - fullNavbar.dropdown - Full Nav Opened Menu with items.
502    Header - dropdownContent     - Item in drop down.

500    Header - miniMenuMask        - Mask
501    Header - miniMenuSection     - Mini menu opened

// OLD 99    Header - fullNavBarMask   1500      - 500 - Full Nav Mask
// OLD 100   Header - dropdown         1510      - 501 - Full Nav Top Menu Items
// OLD 200   Header - fullNavbar.dropdown - 1520 - 502 - Full Nav Opened Menu with items.
//OLD 1     Header - dropdownContent  1520      - 502

// OLD 99    Header - miniMenuMask     500  - Mask
// OLD 1100  Header << miniMenuSection 501  - Mini menu opened


1     WistiaManager - ???
9000  ServiceWorkerWrapper - NOT USED


$zindex-dropdown:          1000 !default;
$zindex-sticky:            1020 !default;
$zindex-fixed:             1030 !default;
$zindex-modal-backdrop:    1040 !default;
$zindex-modal:             1050 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;

For CustomAlert use z-index starting at 1051+

 */






/* =============================================================================
   Fonts
   ========================================================================== */


@font-face {
  font-family: 'BeatriceLight';
  /*src: url('Beatrice-Light.eot');*/
  src:
          url('fonts/Beatrice_Light/Beatrice-Light.eot?#iefix') format('embedded-opentype'),
          url('fonts/Beatrice_Light/Beatrice-Light.woff2') format('woff2'),
          url('fonts/Beatrice_Light/Beatrice-Light.woff') format('woff'),
          url('fonts/Beatrice_Light/Beatrice-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-stretch:normal;
  font-display: block;
}

@font-face {
  font-family: 'BeatriceRegular';
  /*src: url('Beatrice-Light.eot');*/
  src:
          url('fonts/Beatrice_Regular/Beatrice-Regular.eot?#iefix') format('embedded-opentype'),
          url('fonts/Beatrice_Regular/Beatrice-Regular.woff2') format('woff2'),
          url('fonts/Beatrice_Regular/Beatrice-Regular.woff') format('woff'),
          url('fonts/Beatrice_Regular/Beatrice-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-stretch:normal;
  font-display: block;
}


/* =============================================================================
   Variables

   ========================================================================== */
:root {
  --white:#fff;
  --black:#000;



  --light-brown:#ca9a8e;

  --marine:#071d49;
  --blush:#ff9c7e;
  --modal-background:rgba(250, 246, 245, 1.0);

  --accent-background: rgba(233,236,242, 1.0);

  //todo change names
  --light-gray:#e9ecf2;
  --beige:#528afe;
  --medium-brown:#171726;
  --dark-brown:#0b0b17;
}





/* =============================================================================
   Colors
   ========================================================================== */

.black {
  color: var(--black);
}

.white {
  color: var(--white);
}

.dark-brown {
  color: var(--dark-brown)
}

.beige {
  color: var(--beige);
}

.light-gray {
  color: var(--light-gray);
}

.light-brown {
  color: var(--light-brown);
}

.medium-brown {
  color: var(--medium-brown);
}

.error-color {
  color: red;
}


.black-bg {
  background-color: var(--black);
}

.white-bg {
  background-color: var(--white);
}

.dark-brown-bg {
  background-color: var(--dark-brown)
}

.beige-bg {
  background-color: var(--beige) !important;
}

.light-gray-bg {
  background-color: var(--light-gray) !important;
}

.light-brown-bg {
  background-color: var(--light-brown)  !important;;
}

.medium-brown-bg {
  background-color: var(--medium-brown);
}











/* =============================================================================
   Global
   ========================================================================== */


html {
  height: 100%;
}

*:focus, textarea:focus, input:focus, button:focus {
  outline: 0 !important;
  outline: none !important;
  box-shadow: none !important;
}

.not-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Default Font
  font-family: neue-haas-unica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;

  //margin-top: 76px;
  //padding-top: 76px;
  // margin-top: calc( env(safe-area-inset-top, 0px) + 76px); // RJS fullscreen fix - ipad does not adjust.

  //margin-bottom: 96px;
  //padding-bottom: 57px;

  &.no-overflow {
    overflow-x: hidden;
  }
}




/* =============================================================================
   Typography
   ========================================================================== */

.header-base {
  font-family: BeatriceLight, Helvetica, sans-serif;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

h1, .h1 {
  @extend .header-base;
  font-size: 45px;
  line-height: 1.18;
}

h2, .h2 {
  @extend .header-base;
  font-size: 36px;
  line-height: 1.19;
}


h3, .h3 {
  @extend .header-base;
  font-size: 26px;
  line-height: 1.31;
}


h4, .h4 {
  @extend .header-base;
  font-size: 22px;
  line-height: 1.36;
}



.h-32 {
  @extend .header-base;
  font-size: 32px;
  line-height: 1.19;
}







.para-base-type-1 {
  font-family: neue-haas-unica, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.para-1 {
  @extend .para-base-type-1;
  font-size: 20px;
  line-height: 1.4;
}


.para-1-b {
  @extend .para-base-type-1;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
}

.para-2 {
  @extend .para-base-type-1;
  font-size: 18px;
  line-height: 1.44;
}


.para-2-b {
  @extend .para-base-type-1;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.44;
}

.para-3 {
  @extend .para-base-type-1;
  font-size: 16px;
  line-height: 1.13;
}

.para-3-b {
  @extend .para-base-type-1;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
}


.para-4 {
  @extend .para-base-type-1;
  font-size: 14px;
  line-height: 1.43;
}


.para-5 {
  @extend .para-base-type-1;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
}


.para-5-normal {
  @extend .para-base-type-1;
  font-size: 14px;
  line-height: 1.43;
}


.para-6 {
  @extend .para-base-type-1;
  font-size: 10px;
  font-weight: 300;
  line-height: 1.43;
}

.para-26 {
  @extend .para-base-type-1;
  font-size: 26px;
  font-weight: 300;
  line-height: 1.4;
}





.para-base-type-2 {
  font-family: BeatriceLight, Helvetica, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.para-22-light {
  @extend .para-base-type-2;
  font-size: 22px;
  font-weight: 300;
  line-height: 1.36;
}

.para-22-regular {
  @extend .para-base-type-2;
  font-size: 22px;
  font-weight: 300;
  line-height: 1.36;
}



.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}



em {
  font-weight: 500;
  font-style: normal;
}

strong {
  font-weight: 500;
}

.bold {
  font-weight: 500 !important;
}

.bolder {
  font-weight: 700 !important;;
}




//p {
//
//  &.lg {
//  }
//
//  &.md {
//  }
//
//	&.sm {
//	}
//}
//
a {

  color: var(--medium-brown);

  &:hover {
    color: var(--medium-brown);
  }
}


.ul-custom {
  margin: 0 0 15px 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
}


textarea {
  padding:10px 15PX;
}

/* =============================================================================
   Buttons
   ========================================================================== */


.btn {

  border: none;
  // min-height: 44px;

  font-family: neue-haas-unica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;


  &.size_250_50 {
    width:250px;
    height:50px;
  }

  &.min_width_200 {
    min-width:200px;
  }


  &.btn-primary{    
    min-height: 50px;
    border: solid 1px var(--beige);
    background-color: var(--beige);
    text-align: center;
    color: var(--white);
    border-radius: 30px;

    &:hover,
    &:focus
    {
      background-color: var(--light-gray) !important;
      border-color: var(--dark-brown) !important;
      color: var(--dark-brown) !important;
    }


    &:active,
    &.active
    {
      background-color: transparent !important;
      //background-color: var(--light-gray) !important;
      border-color: var(--beige) !important;
      color: var(--dark-brown) !important;
    }
  }


  &.btn-secondary {
    border: 1px solid var(--dark-brown);
    background-color: var(--white) !important;;
    color: var(--dark-brown);
  }


  &.btn-light-brown {
    background-color: var(--light-brown);
    color: var(--white);
  }

  &.btn-dark-brown {
    background-color: var(--dark-brown);
    color: var(--white);
  }

  &.btn-medium-brown {
    background-color: var(--medium-brown);
    color: var(--white);
  }


  //&:hover, &:focus, &:active, &.active {
  //  background-color: var(--white);
  //}
}





.medium_width_view {
  max-width:732px;
}


/* =============================================================================
   iOS Fixes
   ========================================================================== */

/*
  Padding to fix iOS and Safari issues with content on bottom being cut off.
  This is not a Notch Issue.
  On iOS, when the bottom control bar is displayed (on iphones) the bar covers up the content.
  This is a known issue with Apple.
  iPads also have a similiar issue when calculating height of the content area.
 */
.ios-padding-fix,
.safari-padding-fix {
  padding-bottom: 80px;
  // background-color: red;
}




/* =============================================================================
   Icons
   ========================================================================== */
//i.fas, i.far {
//
//
//  &.sm {
//    color: var(--light-color-text) !important;
//    font-size: 1.4em;
//  }
//
//  &.lg {
//    font-size: 1.6em;
//  }
//}






/* =============================================================================
   Forms
   ========================================================================== */
//input[type="search"]::-webkit-search-decoration,
//input[type="search"]::-webkit-search-cancel-button,
//input[type="search"]::-webkit-search-results-button,
//input[type="search"]::-webkit-search-results-decoration { display: none; }
//input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
//input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
//
//
//.form-control, .custom-select {
//
//	&:focus {
//	}
//}
//
//.custom-control-input:focus ~ .custom-control-label::before {
//	box-shadow: none !important;
//}

.custom-input-group-text {
  background-color:white;
  border-color:black;

  &:hover {
    cursor: pointer;
  }
}

.form-group .custom-select, .custom-control .custom-select {
  //background: #fff url(../images/ico-dropdown.png) no-repeat right 0.75rem center;
  cursor: pointer;
  //font-size: 1.4em;
  //line-height: 1.5em;
}


.form-group label {

  margin-bottom: 0;

  font-size: 18px;
  font-weight: 500;
}


.form-group .form-text-small {
  font-style: normal;
  font-weight: normal;
  color:black;
}


.form-group .form-control, .custom-control .form-control, .form-group .custom-select, .custom-control .custom-select {

  height: 50px;

  border: 1px solid var(--black);

  font-size: 18px;
  font-weight: normal;

  color: var(--black);
}


.form-group .invalid-feedback {

  margin-bottom: 0;

  font-size: 14px;
}



.form-group small, .form-group .small {

  margin-bottom: 0;

  font-size: 14px;
  font-weight: 500;
  font-style:italic;
}


//
// Form field validation color overrides.
//
.form-control.is-valid, .was-validated .form-control:valid {
  border-color: var(--black);
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: var(--black);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--black);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: var(--black) !important;
}





//
// Make the Checkbox bigger and change its color.
//

input[type="checkbox"] {
  width: 30px;
  height: 30px;
}
input[type="radio"] {
  width: 30px;
  height: 30px;
}


.custom-checkbox .custom-control-label{

  padding-top: 2px;
  line-height: 22px;
  font-size: 18px;
}

.custom-control-label:before{

  margin-left: 5px;

  border-color: var(--medium-brown);

  width: 20px;
  height: 20px;
}


.custom-checkbox .custom-control-input:checked~.custom-control-label::before{

  //background-color:var(--white);
  background-color:var(--medium-brown);
  border-color: var(--medium-brown);
}


.custom-checkbox .custom-control-input:checked~.custom-control-label::after{
  border-color: var(--medium-brown);
  width: 30px;
  height: 30px;

  margin-top: -5px;

  background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='white' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:focus~.custom-control-label::before{
  border-color: var(--medium-brown);
  //box-shadow: 0 0 0 0 #000, 0 0 0 0.2rem rgba(100, 0, 0, 0.25);
  box-shadow: none;
}





//
// Make the Radio bigger and change its color.
//
.custom-radio .custom-control-input:checked~.custom-control-label::before{

  background-color:var(--white);
  border-color: var(--medium-brown);
}


.custom-radio .custom-control-input:checked~.custom-control-label::after{
  border-color: var(--medium-brown);
  width: 35px;
  height: 35px;

  margin-top: -7px;
  margin-left:-3px;

  background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='sienna' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  //background-image:url(../images/icons/ico-plus.svg);
  background-repeat: no-repeat;
  background-size: 15px 15px;
}

.custom-radio .custom-control-input:focus~.custom-control-label::before{
  border-color: var(--medium-brown);
  box-shadow: 0 0 0 0 #000, 0 0 0 0.2rem rgba(100, 0, 0, 0.25);
}


.form-error-message {
  font-size: 18px;
  color:red;
}

.form-field-error-message {
  font-size: 16px;
  color:red;
}


.form-field-custom-error-message-sm {
  font-size: 14px;
  color:red;
}


.custom-textarea-1 {
  height:150px;
  max-width:730px;
}


/* =============================================================================
   Tables
   ========================================================================== */
//table {
//  &.table {
//
//  }
//
//  &.table-striped {
//  }
//}



/* =============================================================================
   Report Layouts
   ========================================================================== */

.report-type-1-layout {

  table {
    &.report-table-type-1 {
      margin: 0 auto;
      font-size: 12px !important;

      th {
        height: 100%;
        // min-width: 120px;
        padding: 10px 10px;
        text-align: center;
        font-weight: 500;
        color: var(--medium-brown);
      }

      .report-table-header-row {
        border-top: 2px solid var(--beige);
        border-bottom: 2px solid var(--beige);
      }

      tbody tr {
        border-bottom: 1px solid var(--beige);
      }

      td {
        padding: 20px 10px;
        text-align: center;
      }

      .accent-text-1 {
        color: var(--dark-brown);
      }
    }
  }


  .report-result-section {

    overflow-x: scroll;
    overflow-y: hidden;
  }

  .download-report-button {
    height:45px;
    padding-bottom:1px;
  }
}




.fixed-width-30 {
  width:30px;
  min-width:30px;
  max-width:30px;
}

.fixed-width-50 {
  width:50px;
  min-width:50px;
  max-width:50px;
}

.fixed-width-75 {
  width:75px;
  min-width:75px;
  max-width:75px;
}

.fixed-width-100 {
  width:100px;
  min-width:100px;
  max-width:100px;
}

.fixed-width-120 {
  width:120px;
  min-width:120px;
  max-width:120px;
}

.fixed-width-130 {
  width:130px;
  min-width:130px;
  max-width:130px;
}

.fixed-width-150 {
  width:150px;
  min-width:150px;
  max-width:150px;
}

.fixed-width-175 {
  width:175px;
  min-width:175px;
  max-width:175px;
}

.fixed-width-200 {
  width:200px;
  min-width:200px;
  max-width:200px;
}

.fixed-width-225 {
  width:225px;
  min-width:225px;
  max-width:225px;
}

.fixed-width-250 {
  width:250px;
  min-width:250px;
  max-width:250px;
}

.fixed-width-300 {
  width:300px;
  min-width:300px;
  max-width:300px;
}

.fixed-width-350 {
  width:350px;
  min-width:350px;
  max-width:350px;
}



/* =============================================================================
   Carousel
   ========================================================================== */

.carousel-indicators {
  background-color: white;
  bottom:-45px;


  li {
    height:15px;
    width:15px;
    background: var(--light-brown);
    border-radius: 15px;

    border-top:none;
    border-bottom:none;
  }
}



@media only screen and (min-width: 1199px) {
  .custom-carousel {
    height: 530px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1198px) {
 .custom-carousel {
   height: 570px;
 }
}


@media only screen and (min-width: 768px) and (max-width: 999px) {
 .custom-carousel {
   height: 750px;
 }
}

// BAD
//@media only screen and (min-width: 650px) and (max-width: 767px) {
//  .custom-carousel {
//    height: 1200px;
//  }
//}



/* =============================================================================
   Modal
   ========================================================================== */


.modal-content {
  border-radius: 0;
  box-shadow: 1px 3px 13px 0 rgba(73, 39, 40, 0.58);
}

.modal-wide {
  width:79%;
  max-width:79%;
}

.ami-modal {

  &.ami-modal-type-1-border {
    box-shadow: 1px 3px 13px 0 rgba(73, 39, 40, 0.18);
    border: solid 1px var(--light-gray);
  }

  .ami-modal-type-1-body {
    background-color: var(--light-gray);
  }

  .ami-modal-type-2-body {
    background-color: var(--white);
  }

  .ami-modal-close-btn {
    position:absolute;
    top:10px;
    right: 10px;
    z-index: 1;
  }

  .ami-modal-title {
    padding-top:25px;
  }
}


/* =============================================================================
   Date Picker
   ========================================================================== */

.react-datepicker-popper {
  z-index: 10 !important;
}

.ami-date-picker .react-datepicker__input-container input {
  width: 100%;
  border:  1px solid black;
  border-radius: 4px;
  height: 50px;
}

/* =============================================================================
   Header
   ========================================================================== */



/* =============================================================================
   Main
   ========================================================================== */





/* =============================================================================
   Footer
   ========================================================================== */





/* =============================================================================
   Media Queries
   ========================================================================== */


//@media only screen and (max-width: 1199px) {
//}
//
//@media only screen and (max-width: 1024px) {
//}
//
//@media only screen and (max-width: 991px) {
//}
//
//@media only screen and (max-width: 767px) {
//  h1, .h1 {
//  }
//
//  h2, .h2 {
//  }
//
//  h3, .h3 {
//  }
//
//  h4, .h4 {
//  }
//
//  h5, .h5 {
//  }
//
//  h6, .h6 {
//  }
//
//
//  p {
//
//    &.lg {
//    }
//
//    &.md {
//    }
//  }
//}

//@media only screen and (max-width: 575px) {
//  h1, .h1 {
//    font-size: 35px;
//  }
//}


//@media only screen and (max-width: 360px) {
//}


//@media only screen and (max-width: 300px) {
//}

//@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
//}

// @media only screen and (max-width: 900px) {

//   .custom-carousel {
//     height: 1300px;
//   }
// }

// @media only screen and (max-width: 450px) {

//   .custom-carousel {
//     height: 1200px;
//   }
// }
